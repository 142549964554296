const Loader = ({ isOpen }) => {
  return (
    <>
      {isOpen ? (
        <div className="bg-white w-full h-full flex justify-center items-center absolute top-0 left-0 z-50">
          <svg
            aria-label="Axle"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current stroke-current text-black animate-draw-logo"
            stroke="black"
            strokeWidth="2"
            width="44.75"
            height="16"
            viewBox="0 0 179 66"
          >
            <path
              className="animate-fill-logo"
              d="m22.475 38.558 8.2-23.065h.176l7.93 23.065ZM23.797 0 0 62.856h13.926l4.936-14h23.533l4.759 14h14.364L37.988 0Zm53.501 38.911L60.904 62.856h14.014l9.519-14.349 9.519 14.349h14.278L91.4 38.642l14.984-21.3H92.546l-7.928 11.885-8.025-11.885H62.318ZM113.347 0v62.856h12.516V0Zm53.06 35.213h-20.36a14.332 14.332 0 0 1 .573-2.993 9.186 9.186 0 0 1 1.675-3.169 9.479 9.479 0 0 1 3.173-2.509 10.9 10.9 0 0 1 4.98-1.012q4.583 0 6.831 2.465a14.44 14.44 0 0 1 3.127 7.218Zm-20.36 7.923h32.871a32.094 32.094 0 0 0-.881-10.124 24.767 24.767 0 0 0-4.01-8.627 20.223 20.223 0 0 0-7.1-6.03 21.689 21.689 0 0 0-10.136-2.245 23.625 23.625 0 0 0-9.475 1.849 22.4 22.4 0 0 0-7.36 5.062 22.308 22.308 0 0 0-4.759 7.615 26.517 26.517 0 0 0-1.667 9.506 27.682 27.682 0 0 0 1.631 9.684 21.986 21.986 0 0 0 4.627 7.571 20.16 20.16 0 0 0 7.316 4.886 26.054 26.054 0 0 0 9.7 1.717 23.89 23.89 0 0 0 13.214-3.521q5.465-3.521 8.109-11.708h-11.016a7.854 7.854 0 0 1-3.349 4.006 11.167 11.167 0 0 1-6.522 1.893q-5.288 0-8.109-2.729t-3.085-8.805Z"
            ></path>
          </svg>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
