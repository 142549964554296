import { get } from "lodash";
import { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import { ButtonPrimary } from "../components/Button";
import Callout from "../components/Callout";
import { enterMfa } from "../lib/axle";
import CarrierLoader from "./CarrierLoader";
import { useAccountHandler } from "../lib/utility";

const showMfaFaq = (session, loginInformation) => {
  const monitoringEnabled = get(session, "config.scopes.monitoring", false);

  // For more info refer to ign-backend/getSession on how this value is constructed
  let showMfaFaqUrl = get(loginInformation, "carrier.mfaFaqUrl") ? true : false;

  return monitoringEnabled && showMfaFaqUrl;
};

const EnterMfa = ({
  step,
  nextStep,
  setAccountInfo,
  loginInformation,
  session,
  setLinkError,
  posthog,
  setShowNav,
  client,
}) => {
  // Track pageview
  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
  }, [posthog]);

  let retryGetAccountAttempts = 0;
  const carrier = get(loginInformation, "carrier.id");
  const ignitionToken = session.id;
  const type = loginInformation.mfa.type;

  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);

  // Configure carrier loader
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });
  const loadingSteps = [
    "Establishing a secure connection",
    "Contacting your carrier",
    "Sending verification code",
    "Retrieving account information",
  ];

  // Import custom hook for getAccount()
  const getAccount = useAccountHandler({
    ignitionToken,
    setAccountInfo,
    loginInformation,
    nextStep,
    setLinkError,
    setShowNav,
    retryGetAccountAttempts,
  });

  const toShowMfaFaq = showMfaFaq(session, loginInformation);
  // Form logic
  const enterMfaGetAccount = async () => {
    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      if (verificationCode.length < 3) {
        // Backend will throw error if it recieves an MFA code that is too short.
        setError("Verification code must be 3+ characters.");
        return;
      } else {
        // Submit MFA code
        await enterMfa(ignitionToken, carrier, type, verificationCode);
      }
    } catch (error) {
      switch (error.code) {
        // Incorrect mfa code
        case 400:
          setError(error.message);
          return;
        // If session expired, send customer to session-expired
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("session-expired");
          return;
        default:
          setLinkError("enter MFA code");
          nextStep("carrier-error");
          return;
      }
    }

    await getAccount();
  };
  const onSubmit = async (e) => {
    // Prevent default form behavior
    e.preventDefault();

    // Validate required input fields
    if (!verificationCode) {
      setError("Please enter your verification code!");
      return;
    }

    // Enter MFA code and get account details
    await trackPromise(enterMfaGetAccount(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Verifying identity"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${loginInformation.carrier.image}")`,
                // backgroundColor: loginInformation.carrier.color,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
              aria-label={loginInformation.carrier.name}
            ></div>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-xl text-black font-bold">
              Verify your identity
            </h3>
            <p className="text-base text-black">
              Enter the code you received from{" "}
              <b>{loginInformation.carrier.name}</b>.
            </p>
            {toShowMfaFaq && (
              <Callout
                type="info"
                message={
                  <p className="text-sm">
                    To avoid re-verifying your insurance information with{" "}
                    {get(client, "displayName", "provider")}, consider disabling
                    multi-factor authentication on your{" "}
                    {get(loginInformation, "carrier.name")} account. Visit{" "}
                    {get(
                      loginInformation,
                      "carrier.name",
                      "insurance provider"
                    )}
                    's{" "}
                    <a
                      target="_blank"
                      href={get(
                        loginInformation,
                        "carrier.mfaFaqUrl",
                        undefined
                      )}
                      className={
                        get(loginInformation, "carrier.mfaFaqUrl", undefined) &&
                        "hover:text-black focus:text-black font-medium underline"
                      }
                    >
                      website
                    </a>{" "}
                    for more information.
                  </p>
                }
              />
            )}
          </div>
          <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
            <div className="flex flex-col gap-y-6">
              {error && (
                <div
                  className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                  role="status"
                >
                  {" "}
                  {error}{" "}
                </div>
              )}
              <input
                placeholder="Verification code"
                value={verificationCode}
                className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                type="text"
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-y-6">
              <ButtonPrimary
                text={"Submit"}
                //onClick={() => nextStep(step)}
                width={"w-full"}
                type={"submit"}
              />
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default EnterMfa;
