import { getAccessToken, updateIgnition } from "../lib/axle";

// External dependencies
import _, { get, isEmpty } from "lodash";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import Loader from "../components/Loader";
import { constructUriWithSearchParams } from "../lib/utility";

const Success = ({
  step,
  accountInfo,
  policyInfo,
  session,
  client,
  loginInformation,
  posthog,
}) => {
  const isRedirectEnabled = session.redirectUri || session.origin;

  // Get login result from state
  const result = _(loginInformation).get("result");

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
      result: result,
    });
    const success = async () => {
      try {
        // Get auth code for session and account
        const authCode = await getAccessToken(
          accountInfo.id,
          [policyInfo.id], // Adding single policyId to array, to update when supporting multiple policies
          client.id,
          session.id
        );

        if (isEmpty(authCode)) {
          console.error("authCode is empty");
        }

        // Construct webhook event
        const lastEvent = {
          id: `event_${nanoid()}`,
          type: `ignition.completed`,
          data: {
            token: session.id,
            authCode,
            result,
            user: session.user,
            client: client.id,
          },
          createdAt: new Date().toISOString(),
        };

        // Update Ignition session
        const params = {
          status: "completed",
          lastEvent,
          result,
        };
        await updateIgnition(session.id, params);

        if (authCode && isRedirectEnabled) {
          await sendEventAndRedirect(authCode);
        }
      } catch (error) {
        console.error(error);
      }
    };
    trackPromise(success());
  }, [posthog]);

  const sendEventAndRedirect = async (authCodeParam) => {
    // Create params for postMessage or redirectUri
    const paramsToAdd = {
      status: "complete",
      authCode: authCodeParam,
      client: client.id,
      result,
    };

    // Send message to parent window if initialized in an iframe
    if (session.origin) {
      window.parent.postMessage(paramsToAdd, session.origin);
    }

    if (session.redirectUri) {
      const constructedUri = constructUriWithSearchParams(
        session.redirectUri,
        paramsToAdd
      );

      window.location.href = constructedUri;
    }
  };

  return (
    <>
      {isRedirectEnabled ? (
        <Loader isOpen={true} />
      ) : (
        <div className="flex flex-col grow gap-y-8 mt-16">
          <div className="flex justify-center">
            <div
              style={{ backgroundImage: `url("./icons/celebrate.svg")` }}
              className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
              aria-label={step}
            ></div>
          </div>
          <div className="flex flex-col justify-center gap-y-4 text-center text-black">
            <h3 className="font-bold text-xl">
              {get(session, "config.success.header") ?? "All set!"}
            </h3>
            <p className="text-xl">
              {get(session, "config.success.subheader") ??
                "Your information has been shared successfully."}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Success;
